<app-back-button [size]="36" />
<div class="flex h-screen flex-col bg-base-100">
    <div class="grid w-full justify-items-center my-8">
        <img class="max-h-72" src="assets/biglogo.svg">
    </div>
    <div class="flex-1 items-start bg-primary rounded-t-lg">
        <div class="grid justify-items-center items-start pt-8 px-10 gap-4">
            <form [formGroup]="loginForm" class="w-full max-w-xs flex flex-col gap-4 pb-6">
                <label class="input input-block flex items-center">
                    <input type="text" formControlName="username" placeholder="Username" class="text-lg rounded-lg" />
                </label>
                <label class="input input-block flex items-center">
                    <input [type]="passVisible ? 'text' : 'password'" formControlName="password" placeholder="Password" class="flex-grow text-lg rounded-lg" />
                    @if(passVisible) {
                        <span class="text-base-400 material-symbols-outlined" (click)="passVisible = false">visibility_off</span>
                    } @else {
                        <span class="text-base-400 material-symbols-outlined" (click)="passVisible = true">visibility</span>
                    }
                </label>
                <button (click)="signIn()" class="btn btn-secondary rounded-lg btn-block text-lg">Sign In</button>
            </form>

            <!-- <button class="btn btn-secondary btn-outline btn-bordered rounded-lg w-full max-w-xs">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.6" height="14.994" viewBox="0 0 12.6 14.994">
                    <path id="Icon_awesome-apple" data-name="Icon awesome-apple" d="M10.816,10.174A3.125,3.125,0,0,1,12.49,7.335,3.6,3.6,0,0,0,9.654,5.842c-1.188-.094-2.487.693-2.963.693a22.919,22.919,0,0,0-2.558-.659C2.266,5.905.281,7.365.281,10.334a8.343,8.343,0,0,0,.482,2.718c.428,1.229,1.975,4.241,3.589,4.191.844-.02,1.439-.6,2.537-.6,1.065,0,1.617.6,2.558.6,1.627-.023,3.026-2.762,3.435-3.994a3.318,3.318,0,0,1-2.065-3.076Zm-1.895-5.5a3.153,3.153,0,0,0,.8-2.427A3.55,3.55,0,0,0,7.452,3.418a3.2,3.2,0,0,0-.857,2.407A2.811,2.811,0,0,0,8.921,4.677Z" transform="translate(-0.281 -2.25)" fill="currentColor"/>
                </svg>
                Sign In with Apple
            </button>
            <button class="btn btn-secondary btn-outline btn-bordered rounded-lg w-full max-w-xs">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.694" height="14.994" viewBox="0 0 14.694 14.994">
                    <path id="Icon_simple-google" data-name="Icon simple-google" d="M7.857,6.426V9h4.252a4.048,4.048,0,0,1-4.252,3.232,4.733,4.733,0,0,1,0-9.464A4.22,4.22,0,0,1,10.847,3.92L12.88,1.96A7.193,7.193,0,0,0,7.857,0a7.5,7.5,0,0,0,0,14.994c4.327,0,7.2-3.042,7.2-7.326a6.831,6.831,0,0,0-.118-1.243Z" transform="translate(-0.36)" fill="currentColor"/>
                </svg>
                Sign In with Google
            </button> -->
            <p class="text-primary-content my-0"><button (click)="resetPassword()" class="btn btn-link text-secondary">Forgot your password?</button></p>
            <p class="text-primary-content">Don't have an account?<button routerLink="/signup" class="btn btn-link text-secondary">Create Account</button></p>
        </div>
    </div>
</div>
<app-toast #infoToast type="info" />
<app-toast #errorToast type="error" />